'use client';

import cmsItemsArrayIsEqual from '@haaretz/s-common-utils/cmsItemsArrayIsEqual';
import React from 'react';

import type { TagPageFragment } from '@haaretz/s-fragments/TagPage';

export type TagsAtomType = Pick<TagPageFragment, 'contentId' | 'name'>[] | null;

const TagsContext = React.createContext<TagsAtomType | undefined>(undefined);
const TagsSetter = React.createContext<((tags: TagsAtomType) => void) | undefined>(undefined);

export function TagsProvider({
  children,
  value: defaultTags,
}: React.PropsWithChildren<{ value: TagsAtomType }>) {
  const [tags, setTags] = React.useState<TagsAtomType>(defaultTags);

  const tagsSetter = React.useCallback(
    (value: TagsAtomType) => {
      if (Array.isArray(value) && !cmsItemsArrayIsEqual(value, tags)) {
        setTags(value);
      } else if (
        value &&
        !Array.isArray(value) &&
        typeof value === 'object' &&
        Object.keys(value).length &&
        !cmsItemsArrayIsEqual([value], tags)
      ) {
        setTags([value]);
      }
    },
    [tags]
  );

  return (
    <TagsContext.Provider value={tags}>
      <TagsSetter.Provider value={tagsSetter}>{children}</TagsSetter.Provider>
    </TagsContext.Provider>
  );
}

export default function useTags() {
  const context = React.useContext(TagsContext);

  if (context === undefined) {
    throw new Error('useTags must be used within a TagsProvider');
  }

  return context;
}

export function useSetTags() {
  const context = React.useContext(TagsSetter);

  if (context === undefined) {
    throw new Error('useSetTags must be used within a TagsProvider');
  }

  return context;
}

export function useTagsAtom() {
  return [useTags(), useSetTags()] as const;
}
